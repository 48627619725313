.main-content[data-v-55dc65f6] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
.main-content .top[data-v-55dc65f6] {
  width: 100%;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.main-content .top .canvas-box[data-v-55dc65f6] {
  border-radius: 4px;
  width: 50%;
  height: 100%;
  background: #fff;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 10px;
}
.main-content .top .canvas-box .title-box[data-v-55dc65f6] {
  width: calc(100% - 20px);
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.main-content .bottom[data-v-55dc65f6] {
  border-radius: 4px;
  margin-top: 10px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: calc(50% - 10px);
  overflow: scroll;
}
.main-content .bottom .el-container[data-v-55dc65f6] {
  height: 100%;
}
.main-content .bottom .el-container .el-main[data-v-55dc65f6] {
  height: 100%;
  overflow: hidden;
}
.el-main[data-v-55dc65f6] {
  overflow: hidden;
}